import React from 'react';
import Typography from '@material-ui/core/Typography/index';
import {Button, FormControl, InputLabel, MenuItem, Select, Grid, TextField} from '@material-ui/core';

export default class LaunchEndpoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = { config: {} };
  }

  componentDidMount() {
    fetch('config')
      .then(result => result.json())
      .then(config => {
        this.setState({ config: config });
      });
  }

  render() {
    return (
      <div>
            <body>
            <div class="container">
                <div class="jumbotron text-center text-primary">
                    <h1> Welcome to the LTI Tool</h1>
                    <span class="fa fa-lock"></span><p>Login with</p>
                    <a href="/auth/google" class="btn btn-danger"><span class="fa fa-google"></span> SignIn with Google</a>
                </div>
            </div>
            </body>
      </div>
    );
  }
}
